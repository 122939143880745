define("ember-collapsible-panel/components/cp-panel-body/component", ["exports", "@embroider/util", "@ember/component", "@ember/object", "@ember/debug", "ember-collapsible-panel/components/cp-panel-body/template", "@embroider/macros/runtime"], function (_exports, _util, _component, _object, _debug, _template, _runtime) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let LiquidIf, hasRealLiquidIf;
  if ((0, _runtime.macroCondition)(false)) {
    LiquidIf = require("liquid-fire/components/liquid-if").default;
    hasRealLiquidIf = true;
  } else {
    hasRealLiquidIf = false;
  }
  var _default = _component.default.extend({
    layout: _template.default,
    classNames: ['cp-Panel-body'],
    classNameBindings: ['isOpen:cp-is-open'],
    // ideally we'd use a getter here, but that requires Ember 3.16+
    'liquidif': (0, _object.computed)(function () {
      (true && !(hasRealLiquidIf) && (0, _debug.assert)('ember-collapsible-panel\'s cp-panel-body component requires liquid-fire to be present if `shouldAnimate` is used', hasRealLiquidIf));
      return (0, _util.ensureSafeComponent)(LiquidIf, this);
    })
  });
  _exports.default = _default;
});